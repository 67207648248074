export const pageTitle = 'Nasze Plony';

export const footer = '© Designed by Bartosz Maliszewski - Nasze Plony - ';

export const contact = 'Kontakt: ';

export const photoGallery = 'Galeria zdjęć: ';

export const description = 'Opis: ';

export const commodity = 'Towar: ';
